import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import { createGroupInfo } from '../../utils/handleGroupInfo'
import DesktopView from '../../components/IntChamps/DesktopView'
import MobileView from '../../components/IntChamps/MobileView'

const MainContentSyles = styled.div`
  .table-title {
    margin-bottom: 15px;
    text-align: center;
  }

  .mobile-version {
    display: block;
  }

  .desktop-version {
    display: none;
  }

  .content {
    margin-top: 25px;
  }

  @media all and (min-width: 1136px) {
    .mobile-version {
      display: none;
    }

    .desktop-version {
      display: block;
    }
  }
`

export default function InternationalChampions({ data }) {
  const champs = data.champs.nodes
  createGroupInfo(champs)

  return (
    <MainContentSyles className="content-width">
      <h1 className="title">International Champions</h1>

      {champs.length ? (
        <div className="content">
          <div className="desktop-version">
            <DesktopView groups={champs} />
          </div>
          <div className="mobile-version">
            <MobileView groups={champs} showCat />
          </div>
        </div>
      ) : (
        <h3
          style={{
            textAlign: 'center',
          }}
        >
          This section is currently being updated. Come back soon for more
          content.
        </h3>
      )}
    </MainContentSyles>
  )
}

export const query = graphql`
  query IntChampsQuery {
    champs: allSanityIntChamps {
      nodes {
        category
        bari
        bass
        tenor
        lead
        director
        group
        chapter
        id
        year
        slug {
          current
        }
        image {
          alt
          asset {
            fixed(width: 300) {
              ...GatsbySanityImageFixed
            }
            fluid(maxWidth: 500) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`
