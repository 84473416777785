import React, { Component } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { navigate } from 'gatsby'

const ChampListStyles = styled.div`
  overflow: scroll;
  font-size: 80%;
  text-align: center;

  table {
    margin: 0 auto;
    max-width: 1100px;
    border-collapse: collapse;
  }

  table {
    border: 1px solid black;

    thead tr th {
      padding: 10px;
    }

    tbody tr td {
      padding: 0 5px;

      :nth-child(5) {
        padding: 0;
      }
    }

    td,
    th {
      border: 1px solid #ddd;
    }
  }

  tbody tr {
    pointer-events: auto;
    cursor: pointer;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tbody tr:hover {
    background-color: #ddd;
  }

  table {
    thead {
      padding: 0;
      margin: 0;
      background-color: var(--link-hover);
      color: white;
    }
  }

  .group-info {
    /* text-align: left; */
    padding: 0;
    margin: 0;
    list-style: none;
  }
`

const navigationHandler = slug => {
  navigate(`/history/international-champions/${slug}`)
}

class IntDeskTopComponent extends Component {
  constructor({ groups }) {
    super()
    this.groups = groups
    this.rows = this.createRows()
  }

  createRows() {
    const { groups } = this

    return groups.map(group => (
      <tr
        key={group.id}
        data-slug={group.slug.current}
        onClick={() => {
          navigationHandler(group.slug.current)
        }}
      >
        <td>{group.year}</td>
        <td>{group.group}</td>
        <td>{group.category}</td>
        <td>
          <ul className="group-info">
            <li className="chapter">Chapter: {group.chapter}</li>
            <br />
            {group.groupInfo}
          </ul>
        </td>
        <td>
          <Img fixed={group.image.asset.fixed} alt={group.image.alt} />
        </td>
      </tr>
    ))
  }

  render() {
    const { rows } = this

    return (
      <ChampListStyles>
        <table>
          <thead>
            <tr>
              <th>Year</th>
              <th>Group Name</th>
              <th>Category</th>
              <th>Group Info</th>
              <th>Image</th>
            </tr>
          </thead>
          <tbody>{rows.map(row => row)}</tbody>
        </table>
      </ChampListStyles>
    )
  }
}

export default function DesktopChamps({ groups }) {
  return <IntDeskTopComponent groups={groups} />
}
